import React from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"
import Layout from "../components/Layout"
import SEO from "../components/SEO"

const Page = ({ data }) => {
  const { wordpressPage: page } = data

  return (
    <Layout>
      <SEO title={page.title} description={page.acf.meta_description} />
      <section className="page-wrapper">
        <div className="container">
          <div className="columns">
            <div className="column is-10 is-offset-1">
              <h2
                className="is-size-2 has-text-weight-bold is-bold-light has-text-centered"
                dangerouslySetInnerHTML={{ __html: page.title }}
              />
              <p
                className="is-size-4 has-text-centered"
                dangerouslySetInnerHTML={{ __html: page.acf.subtitle }}
              />
              <hr className="is-6" />
              <div
                className="content"
                dangerouslySetInnerHTML={{ __html: page.content }}
              />
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

Page.propTypes = {
  data: PropTypes.object.isRequired,
}

export default Page

export const pageQuery = graphql`
  query PageById($id: String!) {
    wordpressPage(id: { eq: $id }) {
      title
      content
      acf {
        subtitle
        meta_description
      }
    }
  }
`
